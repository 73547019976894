import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { FormDataVehicles, VehicleFuel } from '../../../../types/entities/vehicle';
import { getUniqueItemsByProperties } from '../../../../utils/removeDuplicates';
import Checkbox from '../../../ui/formComponents/checkbox/Checkbox';
import FormElementFull from '../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import { useEffect, useState } from 'react';
import WarningWrapper from 'components/ui/warningWrapper/WarningWrapper';
import Icon from 'components/ui/icon/Icon';
import BlueWrapper from 'components/ui/blueWrapper/BlueWrapper';

type Props = {
  formData: FormDataVehicles;
  formDataFilled?: FormDataVehicles;
  setFormData: (value: any) => void;
  knownVehicles: any[];
  unknownVehicles: any[];
  fuels: VehicleFuel[];
};
const FormProperties = ({
  formData,
  formDataFilled,
  setFormData,
  knownVehicles,
  unknownVehicles,
  fuels
}: Props) => {
  const { t } = useTranslation();
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const [showVehicleFuelDisclaimer, setShowVehicleFuelDisclaimer] = useState(false);
  const [showAdblueDisclaimer, setShowAdblueDisclaimer] = useState(false);

  useEffect(() => {
    const foundFuel = fuels.find((elem) => elem.id === formData.fuel.id);
    if (foundFuel?.fuel === 'adblue') {
      setShowAdblueDisclaimer(true);
    } else {
      setShowAdblueDisclaimer(false);
    }
    if (foundFuel && foundFuel.units.length === 1 && foundFuel.units[0].name === 'litre_(l)') {
      setShowVehicleFuelDisclaimer(true);
    } else {
      setShowVehicleFuelDisclaimer(false);
    }
  }, [formData.fuel.id]);

  const unknownVehiclesMapped = unknownVehicles.map((elem: { type: string }) => {
    return {
      id: elem.type,
      name: t(`vehicles.${elem.type}`)
    };
  });

  const unknownVehiclesFiltered = getUniqueItemsByProperties(unknownVehiclesMapped, ['id']);

  const onChangeUnknownType = async (value: SelectOptionFormat) => {
    setFormData((prev: any) => ({
      ...prev,
      unknownType: value,
      fuel: { id: '', name: '' },
      errors: prev.errors.filter((elem: ErrorType) => elem.error !== 'unknownType')
    }));
  };

  return (
    <FormWrapper>
      <FormSelect
        icon={'/images/icons/tesla.svg'}
        placeholder={t('vehicles.typePlaceholder')}
        label={t('vehicles.type')}
        value={formData.unknownType}
        onChange={onChangeUnknownType}
        options={unknownVehiclesFiltered}
        error={formData.errors.find((elem) => elem.error === 'unknownType')}
      />
      <FormSelect
        icon={'/images/icons/plug.svg'}
        placeholder={t('vehicles.fuelPlaceholder')}
        label={t('vehicles.fuel')}
        value={formData.fuel}
        onChange={onChangeValue('fuel')}
        options={
          fuels
            ? fuels.map((elem) => {
                return {
                  id: elem.id,
                  name: t(`vehicle_fuels.${elem.fuel}`)
                };
              })
            : []
        }
        error={formData.errors.find((elem) => elem.error === 'fuel')}
      />
      <div className='flex flex-col gap-1'>
        {showVehicleFuelDisclaimer && (
          <WarningWrapper
            style={{
              marginTop: '1rem',
              padding: '0.5rem',
              justifyContent: 'flex-start',
              width: 'fit-content',
              gap: '1rem'
            }}>
            <Icon icon='warning' color='warning' />
            <span className='font-12'>{t('vehicles.fuelUnitDisclaimer')}</span>
          </WarningWrapper>
        )}
        {showAdblueDisclaimer && (
          <BlueWrapper
            style={{
              marginTop: '1rem',
              padding: '0.5rem',
              justifyContent: 'flex-start',
              width: 'fit-content',
              gap: '1rem'
            }}>
            <Icon icon='information' color='blocked' />
            <span className='font-12'>{t('vehicles.adBlueDisclaimer')}</span>
          </BlueWrapper>
        )}
      </div>
    </FormWrapper>
  );
};

export default FormProperties;
