import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getPrefixes from '../../../../constants/phonePrefixes';
import { UserContext } from '../../../../context/userContext';
import { updateVehicle } from '../../../../services/api/vehicle';
import { FormDataVehicles, Vehicle, VehicleFuel } from '../../../../types/entities/vehicle';
import FormVehicle from '../formVehicle/FormVehicle';
import useCards from '../hooks/useCards';
import useCountries from '../hooks/useCountries';
import BlueWrapper from 'components/ui/blueWrapper/BlueWrapper';
import Icon from 'components/ui/icon/Icon';
import WarningWrapper from 'components/ui/warningWrapper/WarningWrapper';

type Props = {
  vehicleToEdit: Vehicle;
  editVehicle?: (value: Vehicle, id: string) => void;
};

const EditVehicle = ({ vehicleToEdit, editVehicle }: Props) => {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);

  const cards = useCards();

  const countriesOptions = useCountries();
  const [error, setError] = useState<ErrorType>();

  const handleEditVehicle = async (
    formData: FormDataVehicles,
    knownVehicles: any,
    unknownVehicles: any,
    fuels: VehicleFuel[]
  ) => {
    setError(undefined);
    const unknownVehicleId =
      formData.noBrand || formData.noModel
        ? unknownVehicles.find(
            (vehicle: { type: string }) => vehicle.type === formData.unknownType.id
          )?.id
        : null;
    const vehicleFuelId = formData.fuel.id || null;

    let nameValue = formData.name;
    if (
      (!nameValue ||
        nameValue ===
          `${vehicleToEdit.known_vehicle_brand} ${vehicleToEdit?.known_vehicle_model}`) &&
      !formData.noBrand
    ) {
      nameValue = `${formData.brand.name} ${formData.model.name}`;
    } else if (!nameValue && formData.noBrand) {
      nameValue = formData.unknownType.id;
    }

    const vehicleModified = {
      unknown_vehicle_id: unknownVehicleId ?? undefined,
      ownership: formData.type,
      vehicle_fuel_id: vehicleFuelId ?? undefined,
      is_known: formData.noBrand || formData.noModel ? false : true,
      type: formData.vehicleType.id ? formData.vehicleType.id : vehicleToEdit.type || '',
      name: nameValue,
      license_plate: formData.carRegistration || '',
      country: formData.country.id || vehicleToEdit.country,
      organization_id: user?.selectedOrganization ?? ''
    };

    const newVehicle = await updateVehicle(vehicleToEdit.id, vehicleModified);

    if (typeof newVehicle === 'string' && newVehicle === 'ERR_CANNOT_RECALCULATE_CONSUMPTIONS') {
      setError({
        error: 'general',
        description: t('error.cannotRecalculateConsumptions')
      });
      return;
    }
    if (typeof newVehicle === 'string' && newVehicle.includes('Vehicle with license plate')) {
      setError({
        error: 'carRegistration',
        description: t('error.licensePlateAlreadyExists')
      });
      return;
    }
    if (editVehicle && newVehicle) {
      const foundFuel = fuels?.find((fuel) => fuel.id === newVehicle.vehicle_fuel_id);
      if (foundFuel) {
        newVehicle.vehicle_fuel_id = foundFuel?.id;
      }
      editVehicle(
        {
          ...newVehicle,
          known_vehicle_brand: formData.brand.id,
          known_vehicle_model: formData.model.id,
          unknown_vehicle_type: formData.unknownType.id,
          fuel: foundFuel?.fuel,
          vehicle_fuel: foundFuel?.fuel
        },
        vehicleToEdit.id
      );
    }
  };

  let countryFound = { id: '', name: '' };
  if (vehicleToEdit?.country) {
    const foundValue = getPrefixes(i18n.resolvedLanguage).find(
      (elem) => elem.code === vehicleToEdit.country
    );
    if (foundValue) {
      countryFound = { id: foundValue.code, name: foundValue.name };
    }
  } else {
    countryFound =
      countriesOptions && countriesOptions.length > 0 ? countriesOptions[0] : { id: '', name: '' };
  }

  return (
    <>
      <FormVehicle
        onSubmit={handleEditVehicle}
        title={t('vehicles.editVehicle')}
        description={t('vehicles.editVehicleDescription')}
        disclaimer={
          <WarningWrapper
            style={{
              marginTop: '1rem',
              padding: '1rem',
              justifyContent: 'flex-start',
              width: 'fit-content',
              gap: '1rem'
            }}>
            <Icon icon='warning' color='warning' />
            <span className='font-12'>{t('vehicles.editVehicleDisclaimer')}</span>
          </WarningWrapper>
        }
        formDataFilled={{
          type: cards.find((card) => card.id === vehicleToEdit.ownership)?.id ?? cards[0].id,
          brand: vehicleToEdit.known_vehicle_brand
            ? {
                id: vehicleToEdit.known_vehicle_brand,
                name: vehicleToEdit.known_vehicle_brand
              }
            : {
                id: '',
                name: ''
              },
          noBrand: vehicleToEdit?.unknown_vehicle_type ? true : false,
          unknownType: vehicleToEdit?.unknown_vehicle_type
            ? {
                id: vehicleToEdit.unknown_vehicle_type,
                name: t(`vehicles.${vehicleToEdit.unknown_vehicle_type}`)
              }
            : { id: '', name: '' },
          name:
            vehicleToEdit?.name && !i18n.exists(`vehicles.${vehicleToEdit.name}`)
              ? vehicleToEdit?.name
              : '',
          model: vehicleToEdit?.known_vehicle_model
            ? {
                id: vehicleToEdit.known_vehicle_model,
                name: vehicleToEdit.known_vehicle_model
              }
            : { id: '', name: '' },
          noModel: vehicleToEdit?.unknown_vehicle_type ? true : false,
          carRegistration: vehicleToEdit?.license_plate || '',
          fuel:
            vehicleToEdit?.vehicle_fuel && vehicleToEdit.vehicle_fuel_id
              ? {
                  id: vehicleToEdit.vehicle_fuel_id,
                  name: t(`vehicle_fuels.${vehicleToEdit.vehicle_fuel}`)
                }
              : { id: '', name: '' },
          vehicleType: vehicleToEdit?.type
            ? { id: vehicleToEdit.type, name: t(`vehicles.${vehicleToEdit.type}`) }
            : { id: '', name: '' },
          country: countryFound,
          vehicleFuelFilter: { id: '', name: '' },
          gearboxFilter: { id: '', name: '' },
          cylinderCapacityFilter: { id: '', name: '' },
          vehiclePowerFilter: { id: '', name: '' },
          errors: []
        }}
        errorRequest={error}
      />
    </>
  );
};

export default EditVehicle;
