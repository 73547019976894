import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { FormDataVehicles } from '../../../../types/entities/vehicle';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../ui/formComponents2/formInputs/formText/FormText';
import SelectCardWrapper from '../../../ui/selectCard/SelectCardWrapper';
import useCards from '../hooks/useCards';
import useCountries from '../hooks/useCountries';

type Props = {
  formData: FormDataVehicles;
  setFormData: (value: any) => void;
};
const FormCharacteristics = ({ formData, setFormData }: Props) => {
  const { t } = useTranslation();

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const countriesOptions = useCountries();

  const cards = useCards();

  const onClickCard = (id: string) => {
    setFormData((prev: any) => ({
      ...prev,
      type: id,
      errors: prev.errors.filter((elem: ErrorType) => elem.error !== 'type')
    }));
  };

  return (
    <>
      <div className='vehicle-type'>
        <h3 className='body1-bold-font'>{t('vehicles.vehicleOwnership')}</h3>
        <SelectCardWrapper
          cards={cards}
          onClickCard={onClickCard}
          error={formData.errors.find((elem) => elem.error === 'type')}
          type={
            cards.find((elem) => elem.id === formData.type) ?? {
              id: '',
              icon: '',
              title: ''
            }
          }
        />
      </div>
      <FormWrapper>
        <FormText
          icon={'/images/icons/person.svg'}
          label={t('vehicles.name')}
          placeholder={t('vehicles.namePlaceholder')}
          value={formData.name}
          onChange={onChangeValue('name')}
        />
        <FormText
          icon={'/images/icons/star.svg'}
          label={t('vehicles.carRegistrationOptional')}
          placeholder={t('vehicles.carRegistrationPlaceholder')}
          value={formData.carRegistration}
          onChange={onChangeValue('carRegistration')}
          error={formData.errors.find((elem) => elem.error === 'carRegistration')}
        />
        <FormSelect
          icon={'/images/icons/catalog.svg'}
          placeholder={t('vehicles.vehicleTypePlaceholder')}
          label={t('templates.vehicles.vehicle_use')}
          value={formData.vehicleType}
          onChange={onChangeValue('vehicleType')}
          options={[
            {
              id: 'passenger',
              name: t('vehicles.passenger')
            },
            {
              id: 'freight',
              name: t('vehicles.freight')
            }
          ]}
          error={formData.errors.find((elem) => elem.error === 'vehicleType')}
        />
        <FormSelect
          icon={'/images/icons/pin.svg'}
          placeholder={t('vehicles.selectCountry')}
          label={t('vehicles.country')}
          value={formData.country}
          onChange={onChangeValue('country')}
          options={countriesOptions}
          error={formData.errors.find((elem) => elem.error === 'country')}
          sort={false}
        />
      </FormWrapper>
    </>
  );
};

export default FormCharacteristics;
