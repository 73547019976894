import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDataVehicles, VehicleFuel } from '../../../../types/entities/vehicle';
import checkFormErrors from '../../../../utils/checkFormErrors';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import useGetDataForm from '../hooks/useGetDataForm';
import FormCharacteristics from './FormCharacteristics';
import FormProperties from './FormProperties';
import './styles.scss';

/* enum VehicleSteps {
  CHARACTERISTICS = 'characteristics',
  PROPERTIES = 'properties'
} */

type Props = {
  onSubmit: (
    formData: FormDataVehicles,
    knownVehicles: any[],
    unknownVehicles: any[],
    fuels: VehicleFuel[]
  ) => void;
  formDataFilled?: FormDataVehicles;
  title: string;
  description: string;
  disclaimer?: React.ReactNode;
  errorRequest?: ErrorType;
};
const formDataEmpty: FormDataVehicles = {
  type: '',
  brand: {
    id: '',
    name: ''
  },
  noBrand: true,
  unknownType: {
    id: '',
    name: ''
  },
  name: '',
  model: {
    id: '',
    name: ''
  },
  noModel: true,
  carRegistration: '',
  fuel: {
    id: '',
    name: ''
  },
  vehicleType: {
    id: '',
    name: ''
  },
  country: {
    id: '',
    name: ''
  },
  // optional Filters to filter models
  vehicleFuelFilter: {
    id: '',
    name: ''
  },
  gearboxFilter: {
    id: '',
    name: ''
  },
  cylinderCapacityFilter: {
    id: '',
    name: ''
  },
  vehiclePowerFilter: {
    id: '',
    name: ''
  },
  errors: []
};
const FormVehicle = ({
  onSubmit,
  formDataFilled,
  title,
  description,
  disclaimer,
  errorRequest
}: Props) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState(formDataFilled || formDataEmpty);

  const [loadingButton, setLoadingButton] = useState(false);

  const { knownVehicles, unknownVehicles, fuels } = useGetDataForm({
    brand: formData.brand.id,
    noBrand: formData.noBrand,
    noModel: formData.noModel,
    country: formData.country.id,
    unknownType: formData.unknownType.id
  });

  const handleErrors = () => {
    const optionalFields = [
      'name',
      'vehicleFuelFilter',
      'gearboxFilter',
      'cylinderCapacityFilter',
      'vehiclePowerFilter',
      'model',
      'noModel'
    ];

    if (formData.noBrand || formData.noModel) {
      optionalFields.push('brand');
      optionalFields.push('model');
      optionalFields.push('noModel');
    }
    if (formData.brand.id) {
      optionalFields.push('noBrand');
    }
    if (formData.model.id) {
      optionalFields.push('noModel', 'fuel', 'unknownType');
    }
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    if (newErrors.length > 0 && formData) {
      setFormData({
        ...formData,
        errors: newErrors
      });
    }
    if (newErrors.length !== 0) return newErrors;
    return false;
  };

  const handleSubmit = async () => {
    if (loadingButton || handleErrors()) return;
    setLoadingButton(true);
    onSubmit(formData, knownVehicles, unknownVehicles, fuels ?? []);
    setLoadingButton(false);
  };

  return (
    <div className='form-vehicle'>
      <FormHeader title={title} description={description} />
      {disclaimer}
      <FormCharacteristics formData={formData} setFormData={setFormData} />
      <FormProperties
        formData={formData}
        formDataFilled={formDataFilled}
        setFormData={setFormData}
        knownVehicles={knownVehicles}
        unknownVehicles={unknownVehicles}
        fuels={fuels ?? []}
      />

      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('vehicles.save')}
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      {errorRequest && (
        <span className='error-text-color body2-font'>{errorRequest.description}</span>
      )}
    </div>
  );
};

export default FormVehicle;
