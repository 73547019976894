import { useLocation } from 'react-router-dom';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import CardPanel from 'components/ui/cardPanel/CardPanel';
import LoaderCard from 'components/ui/loaders/loaderCard/LoaderCard';
import Icon from 'components/ui/icon/Icon';
import { IconName } from 'types/utils/iconName';
import {
  generalDatapoints,
  environmentalDatapoints,
  socialDatapoints,
  governanceDatapoints
} from './csrdMockDatapoints';
import DatapointListRenderer, {
  FrameworkDatapointBox,
  FrameworkDatapoint
} from './DatapointListRenderer';
const FrameworkDatapoints = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const category = pathname.split('/')[4];

  const possibleModes = [
    {
      id: 'general',
      title: t('controlPanel.frameworkProgress.generalDatapoints'),
      icon: 'vat'
    },
    {
      id: 'environmental',
      title: t('controlPanel.frameworkProgress.environmentalDatapoints'),
      icon: 'earth'
    },
    {
      id: 'social',
      title: t('controlPanel.frameworkProgress.socialDatapoints'),
      icon: 'members'
    },
    {
      id: 'governance',
      title: t('controlPanel.frameworkProgress.governanceDatapoints'),
      icon: 'own_facility'
    }
  ];
  let pendingDatapoints: FrameworkDatapoint[] = [];
  switch (category) {
    case 'general':
      pendingDatapoints = generalDatapoints;
      break;
    case 'environmental':
      pendingDatapoints = environmentalDatapoints;
      break;
    case 'social':
      pendingDatapoints = socialDatapoints;
      break;
    case 'governance':
      pendingDatapoints = governanceDatapoints;
      break;
    default:
      pendingDatapoints = generalDatapoints;
      break;
  }
  const datapoinBoxes: FrameworkDatapointBox[] = [
    {
      boxColor: '#fafafa',
      dotColor: '#a3aab2',
      datapointBorderColor: '#eaeaea',
      title: t('controlPanel.frameworkProgress.pending'),
      total: pendingDatapoints.length,
      datapoints: pendingDatapoints
    },
    {
      boxColor: '#f0f3fe',
      dotColor: '#6e86f7',
      datapointBorderColor: '#eaeaea',
      title: t('controlPanel.frameworkProgress.inProgress'),
      total: 0,
      datapoints: []
    },
    {
      boxColor: '#ebfafb',
      dotColor: '#38cfd9',
      datapointBorderColor: '#eaeaea',
      title: t('controlPanel.frameworkProgress.completed'),
      total: 0,
      datapoints: []
    }
  ];
  const categoryMode = possibleModes.find((mode) => mode.id === category);
  const loading = false;
  return (
    <div className='framework-dashboard__body'>
      {!loading && categoryMode ? (
        <>
          <CardPanel className='p-6 gap-4'>
            <div className='framework-scope__iconTitle flex gap-4 items-center'>
              <div
                className='icon-wrapper icon-bg-color shadow-purple-dark'
                style={{ height: '48px', width: '48px' }}>
                <Icon
                  icon={categoryMode.icon as IconName}
                  color='gradient'
                  size='large'
                  style={categoryMode.icon === 'own_facility' ? { width: '30px' } : undefined}
                />
              </div>
              <div className='framework-scope__titleNumber flex items-center gap-4 justify-between'>
                <h2 className='font-20 regular-font'>{categoryMode.title}</h2>
              </div>
            </div>
            <DatapointListRenderer datapointBoxes={datapoinBoxes} />
          </CardPanel>
        </>
      ) : (
        <LoaderCard style={{ gridColumn: '1 / -1' }} />
      )}
    </div>
  );
};

export default FrameworkDatapoints;
