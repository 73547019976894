export const environment = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_m110haIBT',
      userPoolWebClientId: '307t40bjthfrtj53hafeastspr',
      oauth: {
        domain: 'dcycle-backend-auth-dcycleprod-prod.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'https://app.onau.eu/callback',
        redirectSignOut: 'https://app.onau.eu/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://api.dcycle.io',
  ldClientSideID: '63e0c1ec62e30213915d1fd7',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'https://app.dcycle.io/'
};
