import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import { getIntensityMetricsByOrganization } from '../../../../services/api/intensityMetrics';
import { getRenewableEnergyKPI } from '../../../../services/api/invoices';
import { findOrCreateSbti, getCriteriaList } from '../../../../services/api/sbti';
import { Organization } from '../../../../types/entities/organization';
import numberToDecimal, { numberToDecimalNonZero } from '../../../../utils/numberToDecimal';
import Icon from '../../../ui/icon/Icon';
import Label from '../../../ui/label/Label';
import LoaderCard from '../../../ui/loaders/loaderCard/LoaderCard';
import Metric from './components/metric/Metric';
import './styles.scss';
import { convertDateBackToFront, convertStringToDate } from '../../../../utils/convertDates';
import Modal from '../../../ui/modal/Modal';
import RequestRenewableEnergy from './components/requestRenewableEnergy/RequestRenewableEnergy';
import moment from 'moment';
import dateToTimestamp from '../../../../utils/dateToTimestamp';
type Props = {
  organization: Organization;
  loading: boolean;
  startDate: Date;
  endDate: Date;
  totalCo2: number;
};

type IntensityMetric = {
  delta: number;
  start_date: string;
  end_date: string;
  unit: string;
  value: number;
};
const OtherMetrics = ({ organization, loading, startDate, endDate, totalCo2 }: Props) => {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const [criteriaList, setCriteriaList] = useState<ISbtiCriteriaBack[]>([]);
  const [intensityMetrics, setIntensityMetrics] = useState<IntensityMetric[]>([]);
  const [renewableEnergy, setRenewableEnergy] = useState<number>(0);
  const [renewableElectricity, setRenewableElectricity] = useState<number>(0);
  const [loaderCard, setLoaderCard] = useState<boolean>(true);
  const [showRequestRenewableEnergy, setShowRequestRenewableEnergy] = useState<boolean>(false);

  // check organization create date
  const organizationCreatedAt = convertStringToDate(
    convertDateBackToFront(organization.created_at)
  );
  let combustion = false;
  // 27/05/2024 with moment to date
  const limitDate = moment('27/05/2024', 'DD/MM/YYYY');

  if (organizationCreatedAt < limitDate.toDate() || flags?.renewableEnergyKPI) {
    combustion = true;
  }

  const fetchSbtiMetrics = async () => {
    //Fetch sbti criteria completed in the sbti form
    const response = await findOrCreateSbti();
    if (response?.response?.status >= 400 || response?.message === 'Network Error') return [];
    const { id } = response;
    const secondResponse = await getCriteriaList(id);
    if (secondResponse?.response?.status >= 400 || response?.message === 'Network Error') return [];
    return secondResponse;
  };

  const fetchData = async () => {
    if (loading) return;
    const requests = [];

    // Fetch intensity metrics of the org
    const requestIntensityMetrics = getIntensityMetricsByOrganization(
      dateToTimestamp(startDate),
      dateToTimestamp(endDate)
    );

    requests.push(requestIntensityMetrics);

    // Fetch Renewable Energy KPI
    const requestRenewableEnergyKPI = getRenewableEnergyKPI(startDate, endDate, combustion, true);
    requests.push(requestRenewableEnergyKPI);

    requests.push(fetchSbtiMetrics());

    try {
      setLoaderCard(true);
      const [resultIntensityMetrics, resultRenewableEnergyKPI, fetchSbtiMetrics] =
        await Promise.all(requests);

      if (resultIntensityMetrics) {
        setIntensityMetrics(resultIntensityMetrics);
      }
      if (combustion) {
        const totalKwh =
          resultRenewableEnergyKPI.data.electricity.total_energy_kwh +
          resultRenewableEnergyKPI.data.combustion.total_energy_kwh;
        const renewableKwh =
          (resultRenewableEnergyKPI.data.electricity.renewable_energy_kwh || 0) +
          (resultRenewableEnergyKPI.data.combustion.renewable_energy_kwh || 0);
        setRenewableEnergy((renewableKwh / totalKwh) * 100);
      }
      setRenewableElectricity(resultRenewableEnergyKPI.data.electricity.renewable_ratio * 100);
      if (fetchSbtiMetrics?.length !== 0) setCriteriaList(fetchSbtiMetrics);
      setLoaderCard(false);
    } catch (error) {
      setLoaderCard(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [loading, startDate, endDate]);

  if (loaderCard || !organization) {
    return <LoaderCard />;
  }

  const percentageProgressSbtiForm = (criteriaList: ISbtiCriteriaBack[]) => {
    if (!criteriaList || criteriaList.length === 0) return 0;
    return Math.trunc(
      ((criteriaList?.filter((elem) => elem.status === 'accepted')?.length || 0) * 100) /
        (criteriaList.length || 1)
    );
  };

  return (
    <div className='dashboard-card card other-metrics-card small-device-flex'>
      <div className='dashboard-card__header flex flex-col gap-4'>
        <span className='font-14 weight-600 on-light-text-color'>
          {t('dashboard.otherMetrics')}
        </span>
        {!combustion && (
          <div className='flex justify-between items-center' style={{ width: '100%' }}>
            <span className='font-12 weight-400 on-light-text-color'>
              {t('dashboard.obtainYour')}{' '}
              <span
                className='highlight-text-color link-style pointer weight-600'
                onClick={() => setShowRequestRenewableEnergy(true)}>
                {t('dashboard.renewableEnergy')}
              </span>
            </span>
            <Label lookAndFeel='primary' size='small'>
              <span className='font-8'>Upgrade</span>
            </Label>
          </div>
        )}
      </div>
      <div className='other-metrics on-card-gray-bg-color'>
        {combustion && (
          <Metric
            value={numberToDecimal(renewableEnergy) + '%'}
            tag={
              <Label lookAndFeel='secondary'>
                <div className='icon-text-wrapper'>
                  <img src='/images/icons/building.svg' alt='leaf' />
                  <span>{t('dashboard.inFacilities')}</span>
                </div>
              </Label>
            }
            description={
              t('dashboard.renewableEnergy')[0].toUpperCase() +
              t('dashboard.renewableEnergy').slice(1)
            }
          />
        )}
        <Metric
          value={numberToDecimal(renewableElectricity) + '%'}
          tag={
            <Label lookAndFeel='secondary'>
              <div className='icon-text-wrapper'>
                <img src='/images/icons/building.svg' alt='leaf' />
                <span>{t('dashboard.inFacilities')}</span>
              </div>
            </Label>
          }
          description={t('dashboard.renewableElectricity')}
        />
        <Metric
          value={
            organization.employee_count_signup > 0
              ? numberToDecimalNonZero(
                  totalCo2 / organization.employee_count_signup / 1000,
                  1
                ).toString()
              : numberToDecimalNonZero(totalCo2 / 1000, 1).toString()
          }
          tag={
            <Label lookAndFeel='secondary'>
              <div className='icon-text-wrapper'>
                <img src='/images/icons/personOnTag.svg' alt='person' />
                <span>{t('dashboard.perEmployee')}</span>
              </div>
            </Label>
          }
          description={'t CO₂ eq.'}
        />
        {/* Iterate over intensity metrics and render them */}
        {intensityMetrics.map((metric, index: number) => {
          return (
            <Metric
              key={metric.unit + index}
              value={numberToDecimalNonZero(metric.value / 1000, 3).toString()}
              tag={
                <Label lookAndFeel='secondary'>
                  <div className='icon-text-wrapper'>
                    <img src='/images/icons/arrowComparisonOnTag.svg' alt='arrowComparison' />
                    <span>{`${metric.delta > 0 ? '+' : ''}${numberToDecimal(
                      metric.delta * 100
                    )}% ${t('dashboard.thanLastYear')}`}</span>
                  </div>
                </Label>
              }
              description={`t CO₂ eq. / ${metric.unit} ${t(
                'dashboard.sinceThe'
              )} ${convertDateBackToFront(metric.start_date)} ${t(
                'dashboard.to'
              )} ${convertDateBackToFront(metric.end_date)}`}
            />
          );
        })}

        {flags?.sbti && (
          <Metric
            value={`${percentageProgressSbtiForm(criteriaList)}%`}
            tag={
              <Label lookAndFeel='secondary'>
                <div className='icon-text-wrapper'>
                  <Icon icon='sbti' color='gradient' />
                  <span>{t('dashboard.sbti')}</span>
                </div>
              </Label>
            }
            description={t('dashboard.sbtiObjectivesForm')}
          />
        )}
      </div>
      <Modal.WithPortal
        show={showRequestRenewableEnergy}
        onClose={() => setShowRequestRenewableEnergy(false)}
        width='548px'
        maxWidth='548px'>
        <RequestRenewableEnergy />
      </Modal.WithPortal>
    </div>
  );
};

export default OtherMetrics;
